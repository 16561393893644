import React from "react";
import Breadcrumb from "../components/Breadcrumbs/Breadcrumb";
import DefaultLayout from "../layout/DefaultLayout";
import DomainTable from "../components/Tables/DomainTable";

const Assets: React.FC = () => {
  return (
    <DefaultLayout>
      <Breadcrumb pageName="Domains" />
      <DomainTable />
    </DefaultLayout>
  );
};

export default Assets;
