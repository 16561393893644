import React from "react";
import { useLocation } from "react-router-dom";

const PaymentRequired: React.FC = () => {
  const location = useLocation();
  const { email, packageName } = location.state || {};

  const handleProceedToPayment = () => {
    // Get the appropriate Stripe URL based on the package
    const stripePlanUrls: { [key: string]: string } = {
      "shield-monthly": import.meta.env.VITE_STRIPE_SHIELD_MONTHLY_URL || "",
      "lighthouse-monthly": import.meta.env.VITE_STRIPE_LIGHTHOUSE_MONTHLY_URL || "",
      "shield-yearly": import.meta.env.VITE_STRIPE_SHIELD_YEARLY_URL || "",
      "lighthouse-yearly": import.meta.env.VITE_STRIPE_LIGHTHOUSE_YEARLY_URL || "",
    };

    // If we have a package name, use its specific Stripe URL
    if (packageName) {
      const stripeUrl = stripePlanUrls[packageName.toLowerCase()];
      if (stripeUrl) {
        const paymentUrl = new URL(stripeUrl);
        if (email) {
          paymentUrl.searchParams.append("prefilled_email", email);
          paymentUrl.searchParams.append("client_reference_id", email);
        }
        window.location.href = paymentUrl.toString();
        return;
      }
    }

    // Fallback to shield-monthly if no package is specified
    const defaultUrl = new URL(stripePlanUrls["shield-monthly"]);
    if (email) {
      defaultUrl.searchParams.append("prefilled_email", email);
      defaultUrl.searchParams.append("client_reference_id", email);
    }
    window.location.href = defaultUrl.toString();
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-midnight p-8">
      <h2 className="text-3xl text-white mb-4">Payment Required</h2>
      <p className="text-white mb-6">
        Your subscription is inactive. Please complete the payment to access all features.
      </p>
      <button
        onClick={handleProceedToPayment}
        className="px-6 py-3 bg-digital text-midnight rounded hover:bg-steel hover:text-white transition font-bold"
      >
        Complete Payment
      </button>
    </div>
  );
};

export default PaymentRequired;
