import React from "react";

interface AssetsTableProps {
  selectedPort: number | null;
  selectedService: string | null;
  domainData: {
    hosts_data: HostData[];
  };
}

interface HostData {
  ip: string;
  domains: string[];
  org: string;
  asn: string;
  ports: number[];
  last_seen: string;
  location: {
    city: string;
    country: string;
  };
  services: ServiceData[];
}

interface ServiceData {
  port: number;
  protocol: string;
  transport_protocol: string;
  components?: { component: string }[];
}

const AssetsTable: React.FC<AssetsTableProps> = ({
  selectedPort,
  selectedService,
  domainData,
}) => {
  if (!domainData || !domainData.hosts_data) {
    return <div>No data available</div>;
  }

  const filteredHosts = domainData.hosts_data.filter((host) => {
    if (selectedPort && host.ports && !host.ports.includes(selectedPort)) {
      return false;
    }
    if (selectedService && host.services) {
      const hasService = host.services.some((service) =>
        service.components?.some(
          (component) => component.component === selectedService,
        ),
      );
      if (!hasService) {
        return false;
      }
    }
    return true;
  });

  return (
    <div className="rounded-2xl border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="w-full overflow-x-auto">
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-gray-2 text-left dark:bg-meta-4">
              <th className="py-4 px-2 font-medium text-black dark:text-white text-sm">
                IP Address
              </th>
              <th className="py-4 px-2 font-medium text-black dark:text-white text-sm">
                Domains
              </th>
              <th className="py-4 px-2 font-medium text-black dark:text-white text-sm">
                Organization
              </th>
              <th className="py-4 px-2 font-medium text-black dark:text-white text-sm">
                ASN
              </th>
              <th className="py-4 px-2 font-medium text-black dark:text-white text-sm">
                Open Ports
              </th>
              <th className="py-4 px-2 font-medium text-black dark:text-white text-sm">
                Last Seen
              </th>
              <th className="py-4 px-2 font-medium text-black dark:text-white text-sm">
                Location
              </th>
              <th className="py-4 px-2 font-medium text-black dark:text-white text-sm">
                Running Services
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredHosts && filteredHosts.length > 0 ? (
              filteredHosts.map((host, hostIndex) => (
                <tr
                  key={hostIndex}
                  className="hover:bg-gray-100 dark:hover:bg-meta-3"
                >
                  <td className="border-b border-[#eee] py-3 px-2 pl-4 dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white break-all">
                      {host.ip}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] py-3 px-2 dark:border-strokedark">
                    <div className="flex flex-wrap">
                      {host.domains.map((domain, domainIndex) => (
                        <span
                          key={domainIndex}
                          className="inline-flex rounded bg-primary py-1 px-2 text-xs font-medium text-white hover:bg-opacity-90 mr-1 mb-1"
                        >
                          {domain}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td className="border-b border-[#eee] py-3 px-2 dark:border-strokedark">
                    <span className="text-sm text-gray-700 dark:text-gray-300 break-words">
                      {host.org}
                    </span>
                  </td>
                  <td className="border-b border-[#eee] py-3 px-2 dark:border-strokedark">
                    <span className="text-sm text-gray-700 dark:text-gray-300 break-words">
                      {host.asn}
                    </span>
                  </td>
                  <td className="border-b border-[#eee] py-3 px-2 dark:border-strokedark">
                    <div className="flex flex-wrap">
                      {host.ports?.map((port, portIndex) => (
                        <span
                          key={portIndex}
                          className="inline-flex rounded bg-primary py-1 px-2 text-xs font-medium text-white hover:bg-opacity-90 mr-1 mb-1"
                        >
                          {port}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td className="border-b border-[#eee] py-3 px-2 dark:border-strokedark">
                    <span className="text-sm text-gray-700 dark:text-gray-300">
                      {new Date(host.last_seen).toLocaleString()}
                    </span>
                  </td>
                  <td className="border-b border-[#eee] py-3 px-2 dark:border-strokedark">
                    <span className="text-sm text-gray-700 dark:text-gray-300 break-words">
                      {`${host.location.city}, ${host.location.country}`}
                    </span>
                  </td>
                  <td className="border-b border-[#eee] py-3 px-2 dark:border-strokedark">
                    {host.services?.map((service, serviceIndex) => (
                      <div
                        key={serviceIndex}
                        className="text-sm text-gray-700 dark:text-gray-300 break-words"
                      >
                        {service.port} {service.protocol}/
                        {service.transport_protocol} -
                        {service.components
                          ?.map((c) => c.component)
                          .join(", ") || "No components"}
                      </div>
                    ))}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="text-center py-4">
                  No matching hosts found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AssetsTable;
