const API_BASE_URL =
  import.meta.env.VITE_ENV === "production"
    ? import.meta.env.VITE_API_BASE_URL
    : import.meta.env.VITE_DEV_API_BASE_URL;

const refreshToken = async () => {
  const storedRefreshToken = localStorage.getItem("refreshToken");
  if (!storedRefreshToken) {
    throw new Error("No refresh token found");
  }

  try {
    const response = await fetch(`${API_BASE_URL}/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refreshToken: storedRefreshToken }),
    });

    if (!response.ok) {
      throw new Error("Failed to refresh token");
    }

    const { accessToken, idToken } = await response.json();
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("idToken", idToken);
    return accessToken;
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};

export const fetchDomainData = async () => {
  try {
    let accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      throw new Error("No access token found in localStorage");
    }

    try {
      const response = await fetch(`${API_BASE_URL}/domain-data`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          // Token expired, try to refresh
          accessToken = await refreshToken();
          // Retry the request with the new token
          const retryResponse = await fetch(`${API_BASE_URL}/domain-data`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (!retryResponse.ok) {
            throw new Error("Failed to fetch domain data after token refresh");
          }

          return await retryResponse.json();
        } else {
          throw new Error("Failed to fetch domain data");
        }
      }

      return await response.json();
    } catch (error) {
      throw error;
    }
  } catch (error) {
    console.error("Error fetching domain data:", error);
    throw error;
  }
};
