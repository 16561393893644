import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import userPool from "../userpool.js";

export const authenticate = (Email: any, Password: any) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: Email,
      Pool: userPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: Email,
      Password: Password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        console.log(result);
        resolve(result);
      },
      onFailure: (err) => {
        console.log(err);
        reject(err);
      },
    });
  });
};

export const logout = () => {
  const user = userPool.getCurrentUser();
  if (user) {
    user.signOut();
    window.location.href = "/";
  }
  return;
};

export const getCurrentUser = () => {
  const userDetails = localStorage.getItem("userDetails");
  return userDetails ? JSON.parse(userDetails) : null;
};
