import React, { useState, useEffect } from "react";
import CardDataStats from "../../components/CardDataStats";
import DashboardMap from "../../components/Maps/DashboardMap";
import DefaultLayout from "../../layout/DefaultLayout";
import VulnerabilitiesChart from "../../components/Charts/VulnerabilitiesChart";
import { fetchDomainData } from "../../api";
import { Loader } from "../../components/ui/loader";
import { Eye, Info, AlertTriangle } from "lucide-react";

const Overview: React.FC = () => {
  const [domainData, setDomainData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await fetchDomainData();
      console.log("Fetched data:", data);
      setDomainData(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  return (
    <DefaultLayout>
      {isLoading ? (
        <div className="flex items-center justify-center min-h-screen">
          <Loader className="w-8 h-8" />
        </div>
      ) : domainData ? (
        <>
          <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
            {/* Left side - 6 cards in 2x3 grid */}
            <div className="col-span-12 xl:col-span-8">
              <div className="grid grid-cols-2 gap-4 md:gap-6 2xl:gap-7.5">
                <CardDataStats 
                  title="Domains Assessed" 
                  total="1"
                  icon={<Eye className="text-primary" />}
                />
                <CardDataStats
                  title="Total Subdomains"
                  total={((domainData.domain_data?.subdomains || []).length || 0).toString()}
                  icon={<Info className="text-primary" />}
                />
                <CardDataStats
                  title="Total Hosts"
                  total={((domainData.hosts_data || []).length || 0).toString()}
                  icon={<Info className="text-primary" />}
                />
                <CardDataStats
                  title="Total DNS Records"
                  total={((domainData.domain_data?.dns_records || []).length || 0).toString()}
                  icon={<Info className="text-primary" />}
                />
                <CardDataStats
                  title="Total Vulnerable Hosts"
                  total={((domainData.vulnerable_hosts || []).length || 0).toString()}
                  icon={<AlertTriangle className="text-warning" />}
                />
                <CardDataStats
                  title="Total Vulnerabilities"
                  total={(domainData.vulnerabilities?.length || 0).toString()}
                  icon={<AlertTriangle className="text-warning" />}
                />
              </div>
            </div>

            {/* Right side - Vulnerability Chart */}
            <div className="col-span-12 xl:col-span-4">
              <VulnerabilitiesChart />
            </div>

            {/* Bottom - World Map (full width) */}
            <div className="col-span-12 mt-4">
              <DashboardMap />
            </div>
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center min-h-screen">
          <Loader className="w-8 h-8" />
        </div>
      )}
    </DefaultLayout>
  );
};

export default Overview;
