import { DNSRecord } from "../../types/domain";

interface SubdomainTableProps {
  domainData: {
    domain_data: {
      dns_records: DNSRecord[];
    };
  };
}

const SubdomainTable: React.FC<SubdomainTableProps> = ({ domainData }) => {
  if (!domainData) {
    return <div>Domain data not available</div>;
  }

  const { dns_records } = domainData.domain_data;

  return (
    <div className="max-w-full overflow-x-auto">
      <div className="min-w-[400px]">
        <h5 className="mb-4 text-l font-semibold text-black dark:text-white">
          DNS Records
        </h5>
        {/* table header */}
        <div className="grid grid-cols-3 rounded-t-[10px] bg-primary px-5 py-4">
          <div>
            <h5 className="font-medium text-white">Type</h5>
          </div>
          <div>
            <h5 className="font-medium text-white">Name</h5>
          </div>
          <div>
            <h5 className="font-medium text-white">Value</h5>
          </div>
        </div>
        {/* table body */}
        <div className="bg-white dark:bg-boxdark">
          {dns_records.map((record: DNSRecord, index: number) => (
            <div
              key={index}
              className="grid grid-cols-3 border-t border-[#EEEEEE] px-5 py-4 dark:border-strokedark"
            >
              <div>
                <p className="text-[#637381] dark:text-bodydark">
                  {record.type}
                </p>
              </div>
              <div>
                <p className="text-[#637381] dark:text-bodydark">
                  {record.domain}
                </p>
              </div>
              <div>
                <p className="text-[#637381] dark:text-bodydark">
                  {record.value}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubdomainTable;
