import { Link } from "react-router-dom";
import { Menu } from "lucide-react";
import DropdownUser from "./DropdownUser";
import DarkModeSwitcher from "./DarkModeSwitcher";
import Logo from "../../images/logo.png";

interface HeaderProps {
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
}

const Header = ({ sidebarOpen, setSidebarOpen }: HeaderProps) => {
  return (
    <header className="sticky top-0 z-50 w-full border-b border-gray-200 bg-white shadow-sm dark:border-gray-700 dark:bg-boxdark">
      <div className="flex h-16 items-center justify-between px-4 md:px-6 2xl:px-11">
        {/* Left: Hamburger & Logo for mobile */}
        <div className="flex items-center gap-4 lg:hidden">
          <button
            aria-controls="sidebar"
            onClick={(e) => {
              e.stopPropagation();
              setSidebarOpen(!sidebarOpen);
            }}
            className="group inline-flex h-10 w-10 items-center justify-center rounded-lg border border-gray-200 bg-white transition-colors hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-2 dark:border-gray-700 dark:bg-boxdark dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          >
            <span className="sr-only">Toggle sidebar</span>
            <Menu className="h-5 w-5 text-gray-600 transition-colors group-hover:text-gray-700 dark:text-gray-400 dark:group-hover:text-white" />
          </button>

          <Link 
            to="/dashboard" 
            className="flex-shrink-0 lg:hidden"
            aria-label="Go to dashboard"
          >
            <img 
              src={Logo}
              alt="Logo" 
              className="h-8 w-auto"
            />
          </Link>
        </div>

        {/* Center: Search (hidden on mobile) */}
        <div className="hidden flex-1 px-4 md:block lg:px-8">
          <div className="relative max-w-md">
            <input
              type="search"
              className="w-full rounded-lg border border-gray-200 bg-gray-50 px-4 py-2 pl-10 text-sm text-gray-900 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:placeholder-gray-400 dark:focus:border-primary"
              placeholder="Search..."
            />
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <svg
                className="h-4 w-4 text-gray-400 dark:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>
        </div>

        {/* Right: Actions */}
        <div className="flex items-center gap-3 md:gap-7">
          {/* Action Buttons */}
          <div className="flex items-center gap-2 md:gap-4">
            {/* Notifications - You can uncomment and implement this */}
            {/* <button
              className="relative rounded-full p-2 text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700"
              aria-label="View notifications"
            >
              <span className="absolute right-1 top-1 h-2 w-2 rounded-full bg-red-500"></span>
              <Bell className="h-5 w-5" />
            </button> */}

            {/* Dark Mode Toggle */}
            <DarkModeSwitcher />
          </div>

          {/* Vertical Divider */}
          <div className="h-8 w-px bg-gray-200 dark:bg-gray-700"></div>

          {/* User Dropdown */}
          <DropdownUser />
        </div>
      </div>
    </header>
  );
};

export default Header;