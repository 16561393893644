import { useEffect, useRef } from "react";
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world";
import "jsvectormap/dist/css/jsvectormap.css";
import { fetchDomainData } from "../../api";

const DashboardMap: React.FC = () => {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstanceRef = useRef<any>(null);

  const getIpLocation = async (ip: string) => {
    try {
      const response = await fetch(`https://ipapi.co/${ip}/json/`);
      const data = await response.json();
      console.log("IP location:", data);
      return {
        country: data.country_name,
        latitude: data.latitude,
        longitude: data.longitude,
        city: data.city,
      };
    } catch (error) {
      console.error("Error fetching IP location:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDomainData();
        updateMap(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.destroy();
      }
    };
  }, []);

  const updateMap = async (domainData: any) => {
    const countryCounts: { [key: string]: number } = {};
    const markers: { name: string; coords: number[] }[] = [];

    for (const host of domainData.hosts_data) {
      const geoData = await getIpLocation(host.ip);
      if (geoData) {
        const { country, latitude, longitude, city } = geoData;
        const name = city || country;
        markers.push({ name, coords: [latitude, longitude] });

        if (countryCounts[country]) {
          countryCounts[country]++;
        } else {
          countryCounts[country] = 1;
        }
      }
    }

    if (mapRef.current && !mapInstanceRef.current) {
      mapInstanceRef.current = new jsVectorMap({
        selector: "#dashboardMap",
        map: "world",
        zoomButtons: false,
        regionStyle: {
          initial: {
            fontFamily: "Satoshi",
            fill: "#A9BDFF",
          },
          hover: {
            fillOpacity: 1,
            fill: "#3056D3",
          },
          selected: {
            fill: "#5D7295",
          },
        },
        series: {
          regions: [
            {
              values: countryCounts,
              scale: ["#C8EEFF", "#0071A4"],
              normalizeFunction: "polynomial",
            },
          ],
        },
        markers: markers,
        markerStyle: {
          initial: {
            fill: "#76EFE4",
            stroke: "#383f47",
          },
        },
        onRegionTooltipShow: function (event: any, code: string) {
          const region = mapInstanceRef.current.regions[code];
          if (region) {
            const countryName = region.name;
            const count = countryCounts[countryName] || 0;
            event.tooltip.innerHTML = `${countryName}: <b>${count} hosts</b>`;
          }
        },
      });
    }
  };

  return (
    <div className="col-span-12 overflow-hidden rounded-2xl border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-6">
      <div className="p-4 md:p-6 xl:p-7.5">
        <div className="mb-7.5 justify-between sm:flex">
          <div className="mb-2">
            <h3 className="text-xl font-semibold text-black dark:text-white">
              Hosts by country
            </h3>
          </div>
        </div>
        <div
          id="dashboardMap"
          ref={mapRef}
          className="mapTwo map-btn h-96"
        ></div>
      </div>
    </div>
  );
};

export default DashboardMap;
