import { ApexOptions } from "apexcharts";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

interface OpenPortsDistributionChartProps {
  onPortClick: (port: number) => void;
  domainData: {
    hosts_data: {
      ports: number[];
    }[];
  };
}

const OpenPortsDistributionChart: React.FC<OpenPortsDistributionChartProps> = ({
  onPortClick,
  domainData,
}) => {
  const [series, setSeries] = useState<{ name: string; data: number[] }[]>([]);
  const [options, setOptions] = useState<ApexOptions>({
    colors: ["#5D7295", "#76EFE4"],
    chart: {
      fontFamily: "Satoshi, sans-serif",
      type: "bar",
      height: 335,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const selectedPort = parseInt(
            chartContext.w.globals.labels[config.dataPointIndex],
            10,
          );
          onPortClick(selectedPort);
        },
      },
    },
    responsive: [
      {
        breakpoint: 1536,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 0,
              columnWidth: "25%",
            },
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 2,
        columnWidth: "25%",
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      title: {
        text: "Port Number",
      },
      categories: [],
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      fontFamily: "Satoshi",
      fontWeight: 500,
      fontSize: "14px",
      markers: {
        radius: 99,
      },
    },
    fill: {
      opacity: 1,
    },
  });

  useEffect(() => {
    const portCounts: { [key: string]: number } = {};

    domainData.hosts_data.forEach((host) => {
      host.ports?.forEach((port) => {
        portCounts[port] = (portCounts[port] || 0) + 1;
      });
    });

    const categories = Object.keys(portCounts);
    const data = Object.values(portCounts);

    setSeries([{ name: "Open Ports", data }]);
    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: categories,
      },
    }));
  }, [domainData]);

  return (
    <div className="col-span-12 rounded-2xl border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-4">
      <div className="mb-4 justify-between gap-4 sm:flex">
        <div>
          <h4 className="text-xl font-semibold text-black dark:text-white">
            Open Ports Distribution
          </h4>
        </div>
      </div>
      <div>
        <div className="w-full">
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={350}
          />
        </div>
      </div>
    </div>
  );
};

export default OpenPortsDistributionChart;
