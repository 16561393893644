import React from "react";
import { Navigate } from "react-router-dom";

interface PrivateRouteProps {
  element: React.ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const accessToken = localStorage.getItem('accessToken');
  
  // If no access token, redirect to login
  if (!accessToken) {
    return <Navigate to="/auth/signin" />;
  }

  // Safely get user details
  let userDetails = null;
  const userDetailsStr = localStorage.getItem('userDetails');
  
  if (userDetailsStr) {
    try {
      userDetails = JSON.parse(userDetailsStr);
    } catch (error) {
      console.error('Error parsing userDetails:', error);
      localStorage.clear(); // Clear invalid data
      return <Navigate to="/auth/signin" />;
    }
  }

  // If no user details but we have a token, clear storage and redirect to login
  if (!userDetails) {
    localStorage.clear();
    return <Navigate to="/auth/signin" />;
  }

  // Check subscription status
  if (userDetails.subscriptionStatus !== "active") {
    return (
      <Navigate
        to="/payment-required"
        state={{ 
          email: userDetails.email, 
          packageName: userDetails.packageName || userDetails.subscribedPlan
        }}
      />
    );
  }

  // If authenticated and has active subscription, render the protected component
  return element;
};

export default PrivateRoute;
