import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SubdomainsChart from "../components/Charts/SubdomainsChart";
import SubdomainTable from "../components/Tables/SubdomainTable";
import DefaultLayout from "../layout/DefaultLayout";
import { fetchDomainData } from "../api";
import { Loader } from "../components/ui/loader";

const DomainOverview = () => {
  const { domain } = useParams<{ domain: string }>();
  const [domainData, setDomainData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (domain) {
        try {
          const data = await fetchDomainData();
          setDomainData(data);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [domain]);

  return (
    <DefaultLayout>
      <div className="h-full min-h-screen">
        {isLoading ? (
          <div className="flex items-center justify-center h-[calc(100vh-10rem)]">
            <Loader className="w-8 h-8" />
          </div>
        ) : domainData && domain ? (
          <>
            <h1 className="mb-4 text-xl font-semibold text-black dark:text-white">
              Domain Overview: {domainData.domain_data.domain}
            </h1>
            <p className="mb-2 text-sm text-gray-600 dark:text-gray-400">
              Health Score: {domainData.domain_data.health_score}
            </p>
            <p className="mb-4 text-sm text-gray-600 dark:text-gray-400">
              Last Updated: {domainData.domain_data.dns_records[0]?.last_seen || "N/A"}
            </p>
            <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
              <div className="col-span-12 xl:col-span-8">
                <SubdomainTable domainData={domainData} />
              </div>
              <div className="col-span-12 xl:col-span-4">
                <SubdomainsChart domain={domain} />
              </div>
            </div>
          </>
        ) : (
          <div>Domain not found</div>
        )}
      </div>
    </DefaultLayout>
  );
};

export default DomainOverview;
