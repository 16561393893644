import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import LogoDark from "../../images/logo.png";
import BackgroundImage from "../../images/bg.png";
import { Loader } from "../../components/ui/loader";

const SignUp: React.FC = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    companyName: '',
    companyUrl: '',
    packageName: ''
  });

  const [error, setError] = useState("");
  const [packageName, setPackageName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPackages, setShowPackages] = useState(false);
  const { package: urlPackage } = useParams();
  const navigate = useNavigate();

  const packages = [
    { id: "shield-monthly", name: "Shield Monthly", description: "Basic protection for your digital assets", price: "$49/month" },
    { id: "lighthouse-monthly", name: "Lighthouse Monthly", description: "Advanced security and monitoring", price: "$99/month" },
    { id: "shield-yearly", name: "Shield Yearly", description: "Basic protection with yearly discount", price: "$470/year" },
    { id: "lighthouse-yearly", name: "Lighthouse Yearly", description: "Advanced security with yearly discount", price: "$950/year" }
  ];

  const handlePackageChange = (newPackage: string) => {
    setPackageName(newPackage);
    setShowPackages(false);
    navigate(`/auth/signup/${newPackage}`, { replace: true });
  };

  useEffect(() => {
    if (urlPackage) {
      const validPackages = packages.map(p => p.id);
      if (validPackages.includes(urlPackage.toLowerCase())) {
        setPackageName(urlPackage);
      } else {
        // Redirect to 404 page
        window.location.href = "/404";
      }
    } else {
      // Set default package if none is provided
      setPackageName("shield-monthly");
      navigate("/auth/signup/shield-monthly", { replace: true });
    }
  }, [urlPackage]);

  // Extract company name from email when email changes
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value.toLowerCase();
    setFormData(prev => {
      const emailParts = email.split('@');
      if (emailParts.length === 2) {
        // Get domain without TLD (e.g., 'gitated' from 'gitated.com')
        const domain = emailParts[1];
        const domainParts = domain.split('.');
        const companyName = domainParts[0]; // Take the first part of the domain
        const fullDomain = domainParts.join('.'); // Full domain for the URL
        
        return {
          ...prev,
          email,
          companyName, // Use domain name without TLD as company name
          companyUrl: fullDomain // Use full domain for URL
        };
      }
      return { ...prev, email };
    });
  };

  const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({
      ...prev,
      companyName: e.target.value
    }));
  };

  const handleCompanyUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({
      ...prev,
      companyUrl: e.target.value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    // Sanitize the data before sending to backend
    const sanitizedCompanyUrl = formData.companyUrl
      .trim()
      .replace(/^(https?:\/\/)?(www\.)?/i, '') // Remove protocol and www
      .split('/')[0]; // Remove anything after the first slash

    const sanitizedCompanyName = formData.companyName.trim();

    try {
      const response = await fetch(
        import.meta.env.VITE_ENV === "production"
          ? import.meta.env.VITE_API_BASE_URL + "/signup"
          : import.meta.env.VITE_DEV_API_BASE_URL + "/signup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: formData.email.toLowerCase(),
            password: formData.password,
            companyName: sanitizedCompanyName,
            companyUrl: sanitizedCompanyUrl,
            packageName: packageName
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to sign up");
      }

      // Always redirect to verification first
      navigate("/auth/verify", { 
        state: { 
          email: formData.email,
          packageName: packageName
        } 
      });
    } catch (error) {
      console.error("Sign up error:", error);
      setError(error instanceof Error ? error.message : "An error occurred during sign up");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="flex flex-col h-screen bg-midnight md:flex-row"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <div className="flex justify-center items-center p-4 md:hidden">
        <img src={LogoDark} alt="Logo" className="w-32" />
      </div>

      <div className="hidden md:flex md:flex-1 md:justify-center md:items-center">
        <img src={LogoDark} alt="Logo" className="w-90 mb-8" />
      </div>

      <div className="flex-1 flex flex-col justify-center items-center bg-midnight p-8 md:p-12 md:h-[98vh] md:mt-[1vh]">
        <h2 className="text-3xl md:text-4xl font-bold text-white mb-4 md:mb-6">
          Create Your Account
        </h2>
        <p className="text-sm md:text-base text-white mb-6 md:mb-8">
          Already have an account?{" "}
          <Link to="/auth/signin" className="text-white">
            Sign In
          </Link>
        </p>

        {error && <p className="text-red-500 mb-4">{error}</p>}

        <div className="w-full max-w-xs md:max-w-sm bg-white p-4 rounded-lg shadow-lg mb-6 relative">
          <div className="flex items-center justify-between">
            <div>
              <div className="text-gray-700 font-bold text-xl">
                {packages.find(pkg => pkg.id === packageName)?.name}
              </div>
              <div className="text-sm text-gray-600">
                {packages.find(pkg => pkg.id === packageName)?.price}
              </div>
            </div>
            <button
              onClick={() => setShowPackages(!showPackages)}
              className="text-sm text-digital hover:text-steel transition-colors flex items-center gap-1"
            >
              Change plan {showPackages ? '↑' : '↓'}
            </button>
          </div>
          
          {showPackages && (
            <div className="absolute left-0 right-0 top-full mt-1 bg-white rounded-lg shadow-lg border border-gray-200 z-10 max-h-[200px] overflow-y-auto">
              <div className="p-2">
                {packages.map((pkg) => (
                  <div
                    key={pkg.id}
                    onClick={() => handlePackageChange(pkg.id)}
                    className={`cursor-pointer p-2 rounded-lg transition-all flex items-center justify-between mb-1 last:mb-0 hover:bg-gray-50 ${
                      packageName === pkg.id
                        ? 'bg-digital/5 text-digital'
                        : ''
                    }`}
                  >
                    <div className="flex-1">
                      <div className="font-medium text-sm">{pkg.name}</div>
                      <div className="text-xs text-gray-500">{pkg.description}</div>
                    </div>
                    <div className="text-sm font-medium text-gray-600 ml-2">
                      {pkg.price}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit} className="w-full max-w-xs md:max-w-sm">
          <div className="mb-4">
            <label className="block text-white mb-2">Email</label>
            <input
              type="email"
              value={formData.email}
              onChange={handleEmailChange}
              className="w-full p-3 rounded bg-white text-gray-800"
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-white mb-2">Password</label>
            <input
              type="password"
              value={formData.password}
              onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
              className="w-full p-3 rounded bg-white text-gray-800"
              placeholder="Enter your password"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2">Company Name</label>
            <input
              type="text"
              value={formData.companyName}
              onChange={handleCompanyNameChange}
              className="w-full p-3 rounded bg-white text-gray-800"
              placeholder="Enter your company name"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2">Company URL</label>
            <input
              type="text"
              value={formData.companyUrl}
              onChange={handleCompanyUrlChange}
              className="w-full p-3 rounded bg-white text-gray-800"
              placeholder="Enter your company URL"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full py-3 rounded bg-digital text-midnight hover:bg-steel hover:text-white transition font-medium flex items-center justify-center"
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader className="w-6 h-6" />
            ) : (
              "Create Account"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignUp;