import React from "react";
import { Link } from "react-router-dom";

const NotFound: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-midnight text-white">
      <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
      <p className="mb-8">
        The page you are looking for doesn't exist or has been moved.
      </p>
      <Link
        to="/dashboard"
        className="bg-digital text-midnight px-6 py-3 rounded hover:bg-steel hover:text-white transition"
      >
        Go to Home
      </Link>
    </div>
  );
};

export default NotFound;
