import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { Domain } from "../../types/domain";
import mockData from "../../mock/mock-data.json";

interface SubdomainsChartState {
  series: number[];
  labels: string[];
}

const SubdomainsChart: React.FC<{ domain: string }> = ({ domain }) => {
  const [state, setState] = useState<SubdomainsChartState>({
    series: [],
    labels: [],
  });

  useEffect(() => {
    const domainData = (mockData as Domain[]).find(
      (data) => data.domain_data.domain === domain,
    );

    if (domainData) {
      const { dns_records } = domainData.domain_data;
      const recordTypeCounts: { [key: string]: number } = {};

      dns_records.forEach((record) => {
        if (recordTypeCounts[record.type]) {
          recordTypeCounts[record.type]++;
        } else {
          recordTypeCounts[record.type] = 1;
        }
      });

      const labels = Object.keys(recordTypeCounts);
      const series = Object.values(recordTypeCounts);

      setState((prevState) => ({
        ...prevState,
        series,
        labels,
      }));
    }
  }, [domain]);

  const brandColors = [
    "#28354F",
    "#5D7295",
    "#303030",
    "#606B79",
    "#E0E0DA",
    "#76EFE4",
  ];

  const options: ApexOptions = {
    chart: {
      type: "donut",
      height: "100%",
      width: "100%",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: brandColors,
    labels: state.labels,
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "14px",
      markers: {
        radius: 2,
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (value) {
          return value.toString();
        },
      },
      theme: "dark",
      style: {
        fontSize: "12px",
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "65%",
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts.w.config.labels[opts.seriesIndex];
      },
      style: {
        fontSize: "12px",
      },
    },
  };

  return (
    <div className="col-span-12 rounded-2xl border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5">
      <div>
        <h3 className="text-xl font-semibold text-black dark:text-white">
          DNS Record Types
        </h3>
      </div>

      <div className="mb-2">
        <div id="subdomainsChart" className="h-[300px] w-full">
          <ReactApexChart
            options={options}
            series={state.series}
            type="donut"
            height="100%"
            width="100%"
          />
        </div>
      </div>

      <div className="-mx-8 flex flex-wrap items-center justify-center gap-y-3">
        {state.labels.map((label, index) => (
          <div key={label} className="sm:w-1/2 w-full px-8">
            <div className="flex w-full items-center">
              <span
                className="mr-2 block h-3 w-full max-w-3 rounded-full"
                style={{
                  backgroundColor: brandColors[index % brandColors.length],
                }}
              ></span>
              <p className="flex w-full justify-between text-sm font-medium text-black dark:text-white">
                <span>{label}</span>
                <span>{state.series[index]}</span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubdomainsChart;
