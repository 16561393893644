import { useState, useEffect } from "react";
import Breadcrumb from "../components/Breadcrumbs/Breadcrumb";
import DefaultLayout from "../layout/DefaultLayout";
import { getCurrentUser } from "../services/authenticate";
import { Mail, Building2, CreditCard } from "lucide-react";

const Settings = () => {
  const [user, setUser] = useState({
    email: "",
    companyName: "",
    subscribedPlan: "",
  });

  useEffect(() => {
    const currentUser = getCurrentUser();
    if (currentUser) {
      setUser({
        email: currentUser.email || "",
        companyName: currentUser.companyName || "",
        subscribedPlan: currentUser.subscribedPlan || "",
      });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: Implement save functionality
  };

  return (
    <DefaultLayout>
      <div className="mx-auto max-w-6xl px-4 py-8">
        <Breadcrumb pageName="Settings" />

        <div className="grid grid-cols-1 gap-8 xl:grid-cols-3">
          {/* Main Settings Form */}
          <div className="xl:col-span-2">
            <div className="overflow-hidden rounded-xl bg-white shadow-sm dark:bg-boxdark">
              <div className="border-b border-gray-200 px-6 py-4 dark:border-gray-700">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Account Settings
                </h2>
              </div>

              <form onSubmit={handleSubmit} className="p-6">
                {/* Email Field */}
                <div className="mb-6">
                  <label 
                    htmlFor="email" 
                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-800"
                  >
                    Email Address
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <Mail className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={user.email}
                      onChange={(e) => setUser({ ...user, email: e.target.value })}
                      className="block w-full rounded-lg border border-gray-200 bg-gray-50 py-3 pl-10 pr-4 text-gray-900 placeholder-gray-500 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                      placeholder="johndoe@securityoverview.com"
                    />
                  </div>
                </div>

                {/* Company Name Field */}
                <div className="mb-6">
                  <label 
                    htmlFor="companyName" 
                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-200"
                  >
                    Company Name
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <Building2 className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="text"
                      id="companyName"
                      name="companyName"
                      value={user.companyName}
                      onChange={(e) => setUser({ ...user, companyName: e.target.value })}
                      className="block w-full rounded-lg border border-gray-200 bg-gray-50 py-3 pl-10 pr-4 text-gray-900 placeholder-gray-500 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                      placeholder="Security Overview"
                    />
                  </div>
                </div>

                {/* Subscription Plan Field */}
                <div className="mb-6">
                  <label 
                    htmlFor="subscribedPlan" 
                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-200"
                  >
                    Subscription Plan
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <CreditCard className="h-5 w-5 text-gray-400" />
                    </div>
                    <div className="block w-full rounded-lg border border-gray-200 bg-gray-50 py-3 pl-10 pr-4 dark:border-gray-600 dark:bg-gray-700">
                      <div className="font-medium text-gray-900 dark:text-white">
                        {user.subscribedPlan || 'No plan selected'}
                      </div>
                      <div className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                        Current subscription plan
                      </div>
                    </div>
                  </div>
                </div>

                {/* Form Actions */}
                <div className="flex justify-end gap-4">
                  <button
                    type="button"
                    className="rounded-lg border border-gray-200 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-600"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="rounded-lg bg-primary px-4 py-2 text-sm font-medium dark:text-black text-white hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </div>

            {/* Customer Support Card */}
            <div className="mt-8 rounded-xl bg-white p-6 shadow-sm dark:bg-boxdark">
              <h3 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
                Need Help?
              </h3>
              <p className="mb-4 text-sm text-gray-600 dark:text-gray-300">
                Our customer support team is here to assist you with any questions or concerns.
              </p>
              <a
                href="mailto:sales@securityoverview.com"
                className="inline-flex items-center text-sm font-medium text-primary hover:text-primary/80 dark:text-white dark:hover:text-gray-300"
              >
                <Mail className="mr-2 h-4 w-4" />
                sales@securityoverview.com
              </a>
            </div>
          </div>

          {/* Right Sidebar - Future Implementation */}
          <div className="hidden xl:block">
            {/* Placeholder for future content (e.g., profile photo, additional settings) */}
            <div className="rounded-xl bg-white p-6 shadow-sm dark:bg-boxdark">
              <h3 className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">
                Coming Soon
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-300">
                Additional settings and customization options will be available soon.
              </p>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Settings;