import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LogoDark from "../../images/logo.png";
import BackgroundImage from "../../images/bg.png";

const TwoStepVerification: React.FC = () => {
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { email, packageName, resend, password } = location.state || {};

  useEffect(() => {
    if (!email) {
      navigate('/auth/signin');
    }
  }, [email, navigate]);

  const handleResendCode = async () => {
    setResendLoading(true);
    setError('');
    try {
      const response = await fetch(
        `${import.meta.env.VITE_ENV === "production"
          ? import.meta.env.VITE_API_BASE_URL
          : import.meta.env.VITE_DEV_API_BASE_URL}/resend-code`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to resend code');
      }

      setResendSuccess(true);
      setTimeout(() => setResendSuccess(false), 5000);
    } catch (error) {
      console.error('Error resending code:', error);
      setError(error instanceof Error ? error.message : 'Failed to resend verification code');
    } finally {
      setResendLoading(false);
    }
  };

  const handleVerify = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch(
        `${import.meta.env.VITE_ENV === "production"
          ? import.meta.env.VITE_API_BASE_URL
          : import.meta.env.VITE_DEV_API_BASE_URL}/verify`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            code: verificationCode,
            password, // Include password if available
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to verify code');
      }

      // If we got a session back, store it
      if (data.session) {
        localStorage.setItem('token', data.session.AccessToken);
        localStorage.setItem('refreshToken', data.session.RefreshToken);
        localStorage.setItem('idToken', data.session.IdToken);
        // Go to dashboard since we're already logged in
        navigate('/dashboard');
        return;
      }

      // Otherwise follow the normal flow
      if (packageName) {
        // Coming from signup - go to payment
        navigate('/payment-required', {
          state: {
            email,
            packageName,
          },
        });
      } else {
        // Coming from signin - go back to signin
        navigate('/auth/signin', {
          state: { verified: true } // Optional: can be used to show success message
        });
      }
    } catch (error) {
      console.error('Verification error:', error);
      setError(error instanceof Error ? error.message : 'Failed to verify code');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="flex flex-col h-screen bg-cover bg-center md:flex-row"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <div className="flex flex-col items-center justify-center w-full p-8 bg-midnight bg-opacity-90">
        <div className="mb-8">
          <img src={LogoDark} alt="Logo" className="h-32" />
        </div>

        <div className="w-full max-w-xs md:max-w-sm">
          <h2 className="text-2xl font-bold text-white mb-6">Verify Your Email</h2>
          <p className="text-white mb-6">
            We've sent a verification code to <strong>{email}</strong>. Please enter it below.
          </p>

          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
              {error}
            </div>
          )}

          {resendSuccess && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
              Verification code resent successfully!
            </div>
          )}

          <form onSubmit={handleVerify} className="space-y-4">
            <div>
              <label className="block text-white mb-2">Verification Code</label>
              <input
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                className="w-full p-3 rounded bg-white text-gray-800"
                placeholder="Enter verification code"
                required
              />
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="w-full py-3 rounded bg-digital text-midnight hover:bg-steel hover:text-white transition font-bold disabled:opacity-50"
            >
              {isLoading ? 'Verifying...' : 'Verify Email'}
            </button>

            <button
              type="button"
              onClick={handleResendCode}
              disabled={resendLoading}
              className="w-full py-3 rounded border border-digital text-digital hover:bg-digital hover:text-midnight transition font-bold disabled:opacity-50"
            >
              {resendLoading ? 'Sending...' : 'Resend Code'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TwoStepVerification;